import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import SEO from "../../components/seo";
import Image from "../../components/widgets/basico/image";
import Layout from "../../components/layouts/layout";
import FormularioBasico from "../../components/widgets/formularios/FormularioBasico";

export default function TallerColision(props){

    const parameters = props.location.search;

    return(
        <Layout selectedMenuItem="taller-colision" parameters={parameters}>
            <SEO
                title="Grupo Rivero - Taller Chevrolet - Autos Usados Seminuevos Venta Renta"
                description="Grupo Rivero - Taller Chevrolet - Taller de Colisión - Servicio Chevrolet - Agencia Chevrolet Monterrey - Seminuevos - Aveo, Beat, Spark, Onix, GM, Buick, Cadillac"
                keywords="Taller Chevrolet, taller de servicio automotriz, seguros chevrolet, servicio chevrolet"
                path="/taller-de-colision"
            />
            <Container>
                <div className="bg-white">
                    <Row>
                        <Col lg={8} md={8} sm={12}>
                            <Image fileName="rivero-body-shop-centro-de-colision-chevrolet.jpg" alt="Taller de colision"/>
                            <h1 className="p-4">
                                El mejor taller Chevrolet General Motors de enderezado y pintura
                                en Monterrey.
                            </h1>
                            <div className="p-4">
                                {" "}
                                <p>
                                Nuestro Taller de Colisión Rivero, es un servicio
                                especializado en unidades colisionadas y/o siniestradas de la
                                línea <strong>General Motors</strong>. Trabajamos con los más
                                altos estándares de calidad y para dejar a completa
                                satisfacción el carro de nuestros clientes. No importa si es
                                un tallón o una colisión mayor, nuestro equipo cuenta con
                                todas las herramientas para reparar y dejarlo como recién
                                salido de agencia.
                                </p>
                                <p>
                                Contamos además, con todas las certificaciones actualizadas de
                                GM, por lo tanto, garantizamos el uso de refacciones, partes y
                                piezas originales, directo de la agencia, para cumplir con los
                                más estrictos y rigurosos estándares de calidad Chevrolet,
                                ayudando a mantener integra la garantía y funcionalidad de tu
                                vehículo.
                                </p>
                                <p>
                                Sin embargo, también tenemos el área de Body Shop para
                                embellecer tu auto con una imagen original y a tu gusto.
                                Puedes personalizar tu carro hasta dejarlo, como tú siempre lo
                                soñaste. Puede ser deportivo, con su spoiler y rines o estilo
                                clásico con colores especiales. Contamos con todos los
                                accesorios para diferenciarte y tener un auto del cual te
                                sientas orgulloso de captar miradas en cada minuto.
                                </p>
                                <p style={{ fontSize: "22px" }}>
                                Aseguradoras con las que trabajamos
                                </p>
                                <p>
                                Además en <strong>Grupo Rivero</strong> tenemos convenios con
                                las principales y más prestigiosas compañías{" "}
                                <strong>aseguradoras en México,</strong> como lo son{" "}
                                <strong>
                                    Aba Seguros, Seguros Banorte Generali, Axa Seguros, Mapfre,
                                    Seguros GNP, Qualitas, Inbursa y Zurich.
                                </strong>
                                </p>
                                <p>
                                Gracias a estos convenios, la cobertura de tu auto se extiende
                                por toda la república mexicana, permitiendo que viajes seguro
                                a todas partes con la confianza de que Grupo Rivero, siempre
                                está listo para respaldarte en caso de algún siniestro o
                                problema
                                </p>
                                <p>
                                Consulta los planes para conocer el seguro ideal para tu
                                presupuesto y el de tu familia.
                                </p>
                                <br />
                                <Image fileName="logo_seguros.png" alt="Seguros"/>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={12} className="p-4">
                            <h2>Formulario de Contacto</h2>
                            <FormularioBasico pagina="taller-de-colision" btnTitulo="Contactar" props={props}>
                                <input
                                    type="hidden"
                                    name="subject"
                                    id="subject"
                                    value="Taller de Colisión"
                                    hidden
                                />
                            </FormularioBasico>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Layout>
    )

}